import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../components/layout';
import { HeadMeta } from '../components/layout/meta';

export default function Page({ data }) {
  const wpPage = data.allWpPage.nodes[0];
  const { title, content, seo } = wpPage;

  return (
    <Layout seoData={seo} wpPage={wpPage}>
      <div className="page">
        <h1 className="page__title">{title}</h1>
        <div className="page__content">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    allWpPage(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        content
        slug
        date
        ...SeoData
      }
    }
  }
`;

export const Head = () => <HeadMeta />;
